import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="TOP" />
    <section>
      <p className="concept-sentence leading-relaxed">
        天然木ツキ板は自然から作り出される意匠。
        <br />
        木目はひとつひとつ異なり、どれもがユニークです。
      </p>
      <p className="concept-sentence leading-relaxed">
        Elegant Selectionは、<span className="font-bold">"その"</span>
        ツキ板にしかない木目の意匠とデザインパネルを併せます。
        <br />
        デザインパネルの凹凸にマッチしたツキ板をセレクトし、最高のコンディションでプロジェクトへお届けします。
      </p>
      <Img
        fluid={data.concept1.childImageSharp.fluid}
        fadeIn={true}
        durationFadeIn={3000}
        alt="concept image"
        className="concept-image"
      />
    </section>
    <section>
      <div className="text-center text-4xl mt-16 mb-8">Concept</div>
      <hr className="w-2/3 m-auto mb-16"></hr>
      <div className="mb-16">
        <h2 className="text-xl">自然が作り出すボリュームと限られたサイズ</h2>
        <p className=" mx-4 my-4 leading-relaxed">
          1つの木からユニークな木目に限らず、ツキ板として利用可能な限られたボリュームとサイズが存在します。
          <br />
          自然が作り出す限られた資源を必要な分だけ、適切な量をインテリアに活かす数量限定のプロジェクトです。
        </p>
      </div>
      <div>
        <h2 className="text-xl text-right">木目が最大限に引き立たつ仕上げ</h2>
        <p className="mx-4 my-4 leading-relaxed text-right">
          Elegant
          Selectionは「木味(きあじ)」を優先的に考えセレクトしております。
          <br />
          着色塗装ではなく、ツキ板本来の色が一番引き立つクリヤー塗装を強くおすすめしております。
        </p>
      </div>
      <div className="md:flex my-12">
        <Img
          fluid={data.concept1.childImageSharp.fluid}
          className="md:w-1/2 md:mx-4 w-full"
          alt="sub images"
        />
        <Img
          fluid={data.concept2.childImageSharp.fluid}
          className="md:w-1/2 md:mx-4 w-full"
          alt="sub images"
        />
      </div>
    </section>
    <section>
      <div className="text-center text-4xl mt-16 mb-8">Line up</div>
      <hr className="w-2/3 m-auto mb-16"></hr>
      <div className="grid md:grid-cols-3 grid-cols-2 mb-16">
        {data.allContentfulTags.edges.map(({ node }) => (
          <Link to={`tags/${node.slug}`} key={node.toString()}>
            <li className="inline-block border border-double rounded-sm text-center text-secondary text-sm bg-gray-200 px-3 py-1 m-2 shadow-sm hover:bg-gray-100 hover:shadow-none">
              {node.tag}
            </li>
          </Link>
        ))}
      </div>
      <div className="grid md:grid-cols-3 md:gap-4 grid-cols-2 gap-2">
        {data.allContentfulProduct.edges.map(({ node }) => (
          <Link to={`product/${node.slug}`} key={node.toString()}>
            <div className="mb-0 shadow-none hover:shadow-lg">
              <img src={node.eyeCatch.fluid.srcWebp} alt="thumbnail" />
              <div className="text-center pb-8">
                <span className="block text-sm px-4 py-0">
                  {node.productName}
                </span>

                <span className="block text-sm px-4 py-0">
                  {node.veneer} No.{node.lot}
                </span>
                <span className="block text-sm px-4 py-0">{node.size}</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    concept1: file(relativePath: { eq: "concept1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concept2: file(relativePath: { eq: "concept2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          eyeCatch {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          veneer
          lot
          productName
          size
          slug
        }
      }
    }
    allContentfulTags {
      edges {
        node {
          slug
          tag
        }
      }
    }
  }
`

export default IndexPage
